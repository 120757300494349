import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetaQuery = () => {
  const {
    markdownRemark: {
      frontmatter: { title, description },
    },
  } = useStaticQuery(graphql`
    query SiteMetaQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          description
          title
        }
      }
    }
  `)

  return { title, description }
}

export default useSiteMetaQuery
